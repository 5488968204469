<template>
  <div>
    <v-data-table
      class="table"
      :items="adsAccounts"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      hide-default-footer
      hide-default-header
      no-data-text=""
      fixed-header
      item-key="name"
      show-expand
    >
      <template v-slot:header>
        <thead>
          <tr class="color-gray" style="border-bottom: 1px solid black">
            <th style="font-size: 15px; font-weight: 400; cursor: pointer">
              {{ $t("my_accounts_page.table_header_number") }}
            </th>
            <th style="font-size: 15px; cursor: pointer; font-weight: 400">
              {{ $t("my_accounts_page.table_header_account_name") }}
            </th>
            <th style="font-size: 15px; cursor: pointer; font-weight: 400">
              {{ $t("status_text") }}
            </th>
            <th
              style="font-size: 15px; cursor: pointer; font-weight: 400"
              class="text-right"
            >
              {{ "Actions" }}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr class="table-row" v-if="adsAccounts.length > 0">
          <td
            class="align-center d-flex pt-5"
            @click="expand(!isExpanded)"
            style="cursor: pointer"
          >
            <v-btn icon>
              <img :src="plusIcon" alt="" v-if="!isExpanded" />
              <img :src="minusIcon" alt="" v-else class="mt-2" />
            </v-btn>
            <span
              class="font-family-raleway-medium color-gray"
              style="font-size: 16px; color: #000 !important"
              >{{ numberWithSpaces(item.resourceName) }}
            </span>
          </td>
          <td class="campaign-name-title" style="font-size: 16px">
            <span
              class="font-family-raleway-medium color-blue"
              @click="$emit('showModal', item.resourceName)"
            >
              {{ item.name }}
            </span>
            <div style="color: #828282 !important" class="font-raleway-regular">
              {{ numberWithSpaces(item.resourceName) }}
              <span v-if="item.linked" style="color: #828282 !important">{{
                $t("my_accounts_page.linked")
              }}</span>
              <span v-else style="color: #828282 !important">
                {{ $t("my_accounts_page.not_linked") }}</span
              >
            </div>
          </td>
          <td>
            <v-btn
              v-if="item.linkedItem"
              class="not-linked-style font-family-raleway-medium"
              style="cursor: default"
            >
              <img :src="linkIcon" alt="" />
              <span v-if="item.linked">{{
                $t("my_accounts_page.linked")
              }}</span>
              <span v-else style="color: #828282 !important">{{
                $t("my_accounts_page.not_linked")
              }}</span>
            </v-btn>
            <v-btn
              class="not-linked-style font-family-raleway-medium mx-2"
              style="cursor: default"
              v-if="item.manager"
            >
              <span style="color: #0057ff">MCC</span>
            </v-btn>
          </td>
          <td class="text-right" >
            <v-btn
              v-if="item.linkedItem && !item.linked && !item.manager"
              color="primary"
              class="not-linked-style font-family-raleway-medium"
              style="cursor: pointer; background-color: #0057ff !important"
              @click="$emit('showModal', item.resourceName)"
            >
              <span style="color: #fff">{{ $t("my_accounts_page.link") }}</span>
            </v-btn>

          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <tr
          v-for="(i, index) in item.children"
          :key="index"
          :colspan="headers.length"
        >
          <td class="text-center">
            <span class="font-family-raleway-medium" style="font-size: 14px"
              >{{ numberWithSpaces(i.resourceName) }}
            </span>
          </td>
          <td class="text-center" style="font-size: 14px">
            <h4
              class="font-family-raleway-medium color-blue"
              @click="$emit('showModal', i.resourceName)"
            >
              {{ i.name }}
            </h4>
            <div style="color: #828282">
              {{ numberWithSpaces(i.resourceName) }}
              <span v-if="item.linked">Linked</span>
              <span v-else> Not linked</span>
            </div>
          </td>
          <td class="text-right">
            <v-btn
              v-if="item.linkedItem"
              class="not-linked-style font-family-raleway-medium"
              style="cursor: default"
            >
              <img :src="linkIcon" alt="" />
              <span v-if="i.linked">Linked</span>
              <span v-else>Not linked</span>
            </v-btn>

            <v-btn
              class="not-linked-style font-family-raleway-medium ml-2"
              style="cursor: default"
              v-if="i.manager"
            >
              <span style="color: #0057ff">MCC</span>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <h4 class="text-center mb-8 font-weight-regular" v-if="isGettingAccounts">
      <span>Loading...</span>
    </h4>
  </div>
</template>

<script>
import linkIcon from "../../assets/icon/link.svg";
import plusIcon from "../../assets/icon/bluePlusIcon.svg";
import minusIcon from "../../assets/icon/blueMinusicon.svg";

export default {
  name: "NestedDataTable",
  props: {
    adsAccounts: Array,
    isGettingAccounts: Boolean,
  },
  data() {
    return {
      linkIcon,
      plusIcon,
      minusIcon,
      expanded: [],
      singleExpand: true,
      dessertHeaders: [
        { text: "Number", value: "number" },
        { text: "Account name", value: "name" },
        { text: "status", value: "" },
      ],
    };
  },
  methods: {
    numberWithSpaces(val) {
      let phone = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
      return phone.replaceAll(" ", "-");
    },
  },
  computed:{
    getBreakPoint(){
      return this.$vuetify.breakpoint.smAndDown;
    },
  }
};
</script>
<style scoped lang="scss">
.table {
  box-shadow: none !important;
  @media screen and (max-width: 800px) {
    display: none;
  }

  thead {
    tr td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>

